<template>
  <div
    class="home-container text-light px-2 px-lg-5"
    v-bind:class="{
      'header-margin': !isAuthenticated(),
    }"
  >
    <b-container fluid class="px-lg-4 px-xl-5 pb-3">
      <b-row class="text-center pt-4">
        <b-col cols="12">
          <h1 class="heading mt-4 pb-1 pt-5">Download the App</h1>
          <b-button variant="link" @click="app_store_click" class="pt-3">
            <img src="../../assets/app-store-dark.svg" class="app-store" />
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="px-sm-4 px-md-5 px-lg-4 px-xl-5">
      <div id="for-artist" class="px-2">
        <b-row class="pb-3">
          <b-col cols="12" class="pb-2 pb-md-5">
            <h3 class="text-right section-heading">For The Artists</h3>

            <p class="text-right section-desc">
              Our award winning studio is built to get you making music.
              Removing the fuss from recording so you can focus on the most<br
                class="d-none d-xl-block"
              />
              important thing - the song. We have built all the tools you need
              to write and record a great song with ease. <br /><br />
              Trackd is built by artists, for artists. Read about our incredible
              history <router-link class="text-underline text-light" :to="{name: 'AboutUs'}">here</router-link>.
            </p>
          </b-col>

          <!-- Image -->
          <b-col class="text-center pr-0 d-md-none" cols="12">
            <img src="../../assets/pages/app/for-artist.png" class="img-fluid" />
          </b-col>

          <!-- Info -->
          <b-col id="feature-list" cols="12" md="6" class="mt-4 mt-md-0">
            <p class="list-heading">Ideas Studio</p>
            <ul>
              <li>
                Professional 8-track studio in your pocket; enabling
                <br class="d-none d-md-block" />
                fast and easy recording anywhere
              </li>
            </ul>

            <p class="list-heading">Search and Collaborate</p>
            <ul>
              <li>
                Discover and collaborate with other Artists and
                <br class="d-none d-md-block" />Producers around the world
              </li>
            </ul>

            <p class="list-heading">Recording Tools</p>
            <ul>
              <li>
                Including, Mixer, Edit, Effects, Levels, Reverb, Bounce
                <br class="d-none d-md-block" />Down, Audio Copy & Paste from
                other apps.
              </li>
            </ul>

            <p class="list-heading">Make Money</p>
            <ul>
              <li>Setup and manage ChipIn® and ChipIn+ payments</li>
            </ul>

            <p class="list-heading">Lyric Tools</p>
            <ul>
              <li>Write, edit and collaborate on lyrics</li>
            </ul>

            <p class="list-heading">Import and Export</p>
            <ul>
              <li>
                Import from other music apps, export in Professional WAV or MP4
                formats
              </li>
            </ul>

            <p class="list-heading">Social Fan Engagement</p>
            <ul>
              <li>
                Message your fans directly and share your work in
                <br class="d-none d-md-block" />Trackd, or other social networks
                using VirtualVinyl®
              </li>
            </ul>

            <p class="list-heading">Analytics</p>
            <ul>
              <li>
                Incredible insights into your fanbase and listeners,<br
                  class="d-none d-md-block"
                />
                you're in control of how you market your music
              </li>
            </ul>
          </b-col>

          <!-- Image -->
          <b-col class="text-right pr-0 d-none d-md-inline-block" cols="6">
            <img src="../../assets/pages/app/for-artist.png" class="img-fluid" />
          </b-col>
        </b-row>
        <div class="border-white my-5 section-border-right"></div>
      </div>

      <div id="music-fan" class="pt-4">
        <b-row>
          <b-col cols="12">
            <h3 class="section-heading">For The Music Fan</h3>

            <p class="mb-3 mb-md-5 pb-3 pb-md-3 section-desc">
              Trackd offers a fan experience like nothing before.<br /><br />
              Discover and listen to your next favorite new artist, engage
              personally with them and share their work. <br />Support the
              artist on their journey by subscribing or paying them directly and
              fairly.
            </p>
          </b-col>

          <!-- Image -->
          <b-col class="text-md-left text-center pl-1" cols="12" md="6">
            <img
              src="../../assets/pages/app/formusicfan.png"
              class="img-fluid pr-3"
            />
          </b-col>

          <b-col id="feature-list" class="mt-4 mt-md-3 pl-0 music-fan-list" cols="12" md="6">
            <p class="list-heading">Discovery</p>
            <ul>
              <li>Discover incredible new artists from around the world.</li>
            </ul>

            <p class="list-heading">Ad Free</p>
            <ul>
              <li>No interruption to your enjoyment</li>
            </ul>

            <p class="list-heading">Playlists</p>
            <ul>
              <li>
                Curate your own collection or listen to professionally curated
                playlists.
              </li>
            </ul>

            <p class="list-heading">Direct Fan to Artist Messaging</p>
            <ul>
              <li>Show your love. Message the artist directly or comment</li>
            </ul>

            <p class="list-heading">Fully Featured Player</p>
            <ul>
              <li>
                Music carousel, artist and song insights, lyrics and ‘back
                cover’ art
              </li>
            </ul>

            <p class="list-heading">JumpIn®</p>
            <ul>
              <li>
                Fancy yourself as a musician? Load the song straight to the
                studio and collaborate!
              </li>
            </ul>

            <p class="list-heading">Keep the music coming</p>
            <ul>
              <li>
                Subscribe to and support your favorite artists fairly through
                ChipIn; enabling them to keep on creating the music you love.
              </li>
            </ul>
          </b-col>
        </b-row>
        <div class="border-white my-5 section-border-left"></div>
      </div>

      <b-row class="pb-5">
        <b-col class="text-center">
          <p class="download">Download Now, Free</p>
          <p class="search-trackd">
            Search 'Trackd' on the App Store or follow<br />the link below.
          </p>
          <b-button class="download-btn mt-2" @click="app_store_click">
            Download Now
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppPage",

  methods: {
    ...mapGetters(["isAuthenticated"]),
    app_store_click() {
      window.open(
        "https://apps.apple.com/gb/app/trackd-songs-start-here/id1496465739",
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
.c-header-buttons-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1000;
  width: 96%;
}

ul {
  margin-bottom: 10px;
  margin-top: 0px;
}

.c-btn-hamburger-icon,
.c-btn-back-icon {
  padding-left: 0px;
  padding-top: 15px;
}

.banner-btn-icon {
  width: 40px !important;
  height: 40px !important;
}

.c-img-hamburger-icon,
.c-img-back-icon {
  width: 52px;
  height: 52px;
}
.home-container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)),
    url("../../assets/pages/app/background.jpg");
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* Center and scale the image nicely */
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}

.header-margin {
  margin-top: 100px;
}

.platform {
  font-size: 14px;
  font-weight: 900;
  line-height: 30px;
}

.heading {
  font-size: 30px;
  font-weight: 200;
  line-height: 60px;
}

.section-heading {
  font-size: 24px;
  font-weight: 300;
  line-height: 90px;
}

.app-store {
  height: 40px;
}

.for-music-fan {
  height: 250px;
}

.section-desc {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.list-heading {
  font-size: 12px;
  font-weight: 400;
  line-height: 0px;
  margin-bottom: 5px;
}

#feature-list li {
  font-size: 11px;
  font-weight: 300;
  line-height: 16px;
}

.section-border-right {
  margin-right: -19px;
  margin-left: 24%;
}

.section-border-left {
  margin-left: -10%;
  width: 90%;
}

.download {
  font-size: 30px;
  font-weight: 900;
  line-height: 90px;
}

.search-trackd {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

.download-btn {
  background-color: transparent;
  border: 1px solid white;
  width: 144px;
  height: 37px;
  border-radius: 10px;
}

@media (min-width: 576px) {
  .platform {
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
  }

  .heading {
    font-size: 48px;
    font-weight: 200;
    line-height: 90px;
  }

  .section-heading {
    font-size: 24px;
    font-weight: 300;
    line-height: 90px;
  }

  .app-store {
    height: 40px;
  }


  .section-desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .list-heading {
    font-size: 12px;
    font-weight: 400;
    line-height: 0px;
  }

  #feature-list li {
    font-size: 11px;
    font-weight: 300;
    line-height: 16px;
  }

  .section-border-right {
    margin-right: -9.5%;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }

  .download {
    font-size: 30px;
    font-weight: 900;
    line-height: 90px;
  }

  .search-trackd {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
  }

  .download-btn {
    background-color: transparent;
    border: 1px solid white;
    width: 144px;
    height: 37px;
    border-radius: 10px;
  }
}

@media (min-width: 768px) {
  .platform {
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
  }

  .heading {
    font-size: 60px;
    font-weight: 200;
    line-height: 90px;
  }

  .section-heading {
    font-size: 30px;
    font-weight: 300;
    line-height: 90px;
  }

  .app-store {
    height: 50px;
  }

  .section-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .list-heading {
    font-size: 14px;
    font-weight: 400;
    line-height: 0px;
  }

  #feature-list li {
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
  }

  .section-border-right {
    margin-right: -55px;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }

  .download {
    font-size: 40px;
    font-weight: 900;
    line-height: 90px;
  }

  .search-trackd {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }

  .download-btn {
    background-color: transparent;
    border: 1px solid white;
    width: 144px;
    height: 37px;
    border-radius: 10px;
  }
}

@media (min-width: 992px) {
  .platform {
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
  }

  .heading {
    font-size: 80px;
    font-weight: 200;
    line-height: 90px;
  }

  .section-heading {
    font-size: 40px;
    font-weight: 300;
    line-height: 90px;
  }

  .app-store {
    height: 60px;
  }

  .section-desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  .list-heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 8px;
  }

  #feature-list li {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
  }

  .section-border-right {
    margin-right: -70px;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }

  .download {
    font-size: 40px;
    font-weight: 900;
    line-height: 90px;
  }

  .search-trackd {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }

  .download-btn {
    background-color: transparent;
    border: 1px solid white;
    width: 144px;
    height: 37px;
    border-radius: 10px;
  }
}

@media (min-width: 1200px) {
  .section-heading {
    font-size: 40px;
    font-weight: 300;
    line-height: 90px;
  }


  .music-fan-list {
    margin-left: -20px;
  }

  .section-desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }

  .list-heading {
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
  }

  #feature-list li {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }

  .download {
    font-size: 40px;
    font-weight: 900;
    line-height: 90px;
    margin-bottom: 0px;
  }

  .search-trackd {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }

  .download-btn {
    background-color: transparent;
    border: 1px solid white;
    width: 144px;
    height: 37px;
    border-radius: 10px;
  }

  .section-border-right {
    margin-right: -104px;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }
}

@media (max-width: 992px) {
  .list-heading {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .list-heading {
    margin-bottom: 10px;
  }
}
@media (max-width: 414px) {
  .home-container {
    background-size: 135%;
  }
}

@media (max-width: 414px) {
  .list-heading {
    margin-bottom: 10px;
  }
}

@media (max-width: 375px) {
  .list-heading {
    margin-bottom: 10px;
  }

  .home-container {
    background-size: 135%;
  }
}

@media (max-width: 320px) {
  .list-heading {
    margin-bottom: 10px;
  }
}
</style>
